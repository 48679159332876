export const HospitalActionTypes = {
    API_RESPONSE_SUCCESS: '@@hospital/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@hospital/API_RESPONSE_ERROR',

    GET_HOSPITAL_LIST: '@@hospital/GET_HOSPITAL_LIST',
    GET_HOSPITAL_ID: '@@hospital/GET_HOSPITAL_ID',
    ADD_HOSPITAL: '@@hospital/ADD_HOSPITAL',
    UPDATE_HOSPITAL: '@@hospital/UPDATE_HOSPITAL',
    DELETE_HOSPITAL: '@@hospital/DELETE_HOSPITAL',
    BACK_RESET_STATE_HOSPITAL: '@@hospital/BACK_RESET_STATE_HOSPITAL',
    RESET_HOSPITAL: '@@hospital/RESET_HOSPITAL'
};
