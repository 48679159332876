import { APICore } from './apiCore';

const api = new APICore();

function requestUploadImage(params) {
    const baseUrl = 'request_image/upload';
    return api.createWithFile(`${baseUrl}`, params);
}

function deleteImage(params) {
    const baseUrl = 'request_image/del_image/' + params.request_image_id;
    return api.delete(`${baseUrl}`);
}

export { requestUploadImage, deleteImage };
