// @flow
import { TeamActionTypes } from './constants';

type TeamAction = { type: string, payload: {} | string };

// common success
export const teamApiResponseSuccess = (actionType: string, data: any): TeamAction => ({
    type: TeamActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const teamApiResponseError = (actionType: string, error: string): TeamAction => ({
    type: TeamActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getTeamList = (params): TeamAction => ({
    type: TeamActionTypes.GET_TEAM_LIST,
    payload: { params },
});

export const getTeamId = (team_id): TeamAction => ({
    type: TeamActionTypes.GET_TEAM_ID,
    payload: { team_id },
});

export const addTeam = (team_name: String, role: String, max_working_hours): TeamAction => ({
    type: TeamActionTypes.ADD_TEAM,
    payload: { team_name, role, max_working_hours },
});

export const updateTeam = (
    team_id: Number,
    team_name: String,
    role: String,
    user_id: Number,
    max_working_hours
): TeamAction => ({
    type: TeamActionTypes.UPDATE_TEAM,
    payload: { team_id, team_name, role, user_id, max_working_hours },
});

export const deleteTeam = (team_id: Number): TeamAction => ({
    type: TeamActionTypes.DELETE_TEAM,
    payload: team_id,
});

export const backResetStateTeam = (): TeamAction => ({
    type: TeamActionTypes.BACK_RESET_STATE_TEAM,
    payload: {},
});

export const resetTeam = (): TeamAction => ({
    type: TeamActionTypes.RESET_TEAM,
    payload: {},
});
