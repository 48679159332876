// @flow
import { UserActionTypes } from './constans';

const INIT_STATE = {
    userList: [],
    loading: false,
};

const User = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UserActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case UserActionTypes.GET_USER_LIST: {
                    return {
                        ...state,
                        userList: action.payload.data.data,
                        loading: false,
                        totalData: action.payload.data.total
                    };
                }
                case UserActionTypes.GET_USER_ID: {
                    return {
                        ...state,
                        user: action.payload.data.data,
                        loading: false,
                    };
                }
                case UserActionTypes.ADD_USER: {
                    return {
                        ...state,
                        loading: false,
                        userUpdated: true,
                    };
                }
                case UserActionTypes.UPDATE_USER: {
                    return {
                        ...state,
                        loading: false,
                        userUpdated: true,
                    };
                }
                case UserActionTypes.DELETE_USER: {
                    return {
                        ...state,
                        loading: false,
                        userUpdated: true,
                    };
                }
                default:
                    return { ...state };
            }

        case UserActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case UserActionTypes.GET_USER_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case UserActionTypes.GET_USER_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case UserActionTypes.ADD_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case UserActionTypes.UPDATE_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case UserActionTypes.DELETE_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case UserActionTypes.GET_USER_LIST:
            return { ...state, loading: true };
        case UserActionTypes.GET_USER_ID:
            return { ...state, loading: true };
        case UserActionTypes.ADD_USER:
            return { ...state, loading: true };
        case UserActionTypes.UPDATE_USER:
            return { ...state, loading: true };
        case UserActionTypes.DELETE_USER:
            return { ...state, loading: true };
        case UserActionTypes.BACK_RESET_STATE_USER:
            return {
                ...state,
                userUpdated: null,
                error: null,
                user: null,
            };
        case UserActionTypes.RESET_USER:
            return {
                ...state,
                loading: false,
                error: null,
                user: null,
                userUpdated: null,
            };
        default:
            return { ...state };
    }
};

export default User;
