import { APICore } from './apiCore';

const api = new APICore();

function getUpdate(params: any): any {
    const baseUrl = '/update/get';
    return api.get(`${baseUrl}`, params);
}

function updateUpdate(params: any): any {
    const baseUrl = 'update/update';
    return api.update(`${baseUrl}`, params);
}

function updateList(params: any): any {
    const baseUrl = '/update/list';
    return api.get(`${baseUrl}`, params);
}

function doneUpdate(params: any): any {
    const baseUrl = 'update/done';
    return api.update(`${baseUrl}`, params);
}

function updateAnalyst(params: any): any {
    const baseUrl = 'update/update_analyst';
    return api.update(`${baseUrl}`, params);
}

function HitUrgent(params: any): any {
    const baseUrl = 'update/hit_urgent';
    return api.update(`${baseUrl}`, params);
}

export { getUpdate, updateUpdate, updateList, doneUpdate, updateAnalyst, HitUrgent };
