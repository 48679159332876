// @flow
import { PrivillegeActionTypes } from './constants';

const INIT_STATE = {
    userType: [],
    loading: true,
};

const Privillege = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PrivillegeActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PrivillegeActionTypes.GET_PRIVILLEGE_LIST: {
                    return {
                        ...state,
                        privillegeList: action.payload.data,
                        totalData: action.payload.total,
                        loading: false,
                    };
                }
                case PrivillegeActionTypes.GET_USER_TYPE: {
                    return {
                        ...state,
                        userType: action.payload.data.data,
                        loading: false,
                    };
                }

                case PrivillegeActionTypes.GET_PRIVILLEGE_ID: {
                    return {
                        ...state,
                        privillege: action.payload.data.data,
                        loading: false,
                    };
                }
                case PrivillegeActionTypes.ADD_PRIVILLEGE: {
                    return {
                        ...state,
                        loading: false,
                        privillegeUpdated: true,
                    };
                }
                case PrivillegeActionTypes.UPDATE_PRIVILLEGE: {
                    return {
                        ...state,
                        loading: false,
                        privillegeUpdated: true,
                    };
                }
                case PrivillegeActionTypes.DELETE_PRIVILLEGE: {
                    return {
                        ...state,
                        loading: false,
                        privillegeUpdated: true,
                    };
                }
                default:
                    return { ...state };
            }

        case PrivillegeActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PrivillegeActionTypes.GET_PRIVILLEGE_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case PrivillegeActionTypes.GET_USER_TYPE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case PrivillegeActionTypes.GET_PRIVILLEGE_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case PrivillegeActionTypes.ADD_PRIVILLEGE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case PrivillegeActionTypes.UPDATE_PRIVILLEGE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case PrivillegeActionTypes.DELETE_PRIVILLEGE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case PrivillegeActionTypes.GET_PRIVILLEGE_LIST:
            return { ...state, loading: true };
        case PrivillegeActionTypes.GET_USER_TYPE:
            return { ...state, loading: true };
        case PrivillegeActionTypes.GET_PRIVILLEGE_ID:
            return { ...state, loading: true };
        case PrivillegeActionTypes.ADD_PRIVILLEGE:
            return { ...state, loading: true };
        case PrivillegeActionTypes.UPDATE_PRIVILLEGE:
            return { ...state, loading: true };
        case PrivillegeActionTypes.DELETE_PRIVILLEGE:
            return { ...state, loading: true };
        case PrivillegeActionTypes.BACK_RESET_STATE_PRIVILLEGE:
            return {
                ...state,
                privillegeUpdated: null,
                error: null,
                privillege: null,
            };
        case PrivillegeActionTypes.RESET_PRIVILLEGE:
            return {
                ...state,
                loading: false,
                error: null,
                privillege: null,
                privillegeUpdated: null,
            };
        default:
            return { ...state };
    }
};

export default Privillege;
