// @flow
import { TeamActionTypes } from './constants';

const INIT_STATE = {
    teamList: [],
    loading: true,
};

const Team = (state = INIT_STATE, action) => {
    // console.log(action);
    switch (action.type) {
        case TeamActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TeamActionTypes.GET_TEAM_LIST: {
                    // console.log(TeamActionTypes.GET_TEAM_LIST)
                    return {
                        ...state,
                        teamList: action.payload.data.data,
                        loading: false,
                        totalData: action.payload.data.total,
                        
                       
                    };
                    
                }
                case TeamActionTypes.GET_TEAM_ID: {
                    return {
                        ...state,
                        team: action.payload.data.data,
                        loading: false,
                    };
                }
                case TeamActionTypes.ADD_TEAM: {
                    return {
                        ...state,
                        loading: false,
                        teamUpdated: true,
                    };
                }
                case TeamActionTypes.UPDATE_TEAM: {
                    return {
                        ...state,
                        loading: false,
                        teamUpdated: true,
                    };
                }
                case TeamActionTypes.DELETE_TEAM: {
                    return {
                        ...state,
                        loading: false,
                        teamUpdated: true,
                    };
                }
                default:
                    return { ...state };
            }

        case TeamActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TeamActionTypes.GET_TEAM_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case TeamActionTypes.GET_TEAM_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case TeamActionTypes.ADD_TEAM: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case TeamActionTypes.UPDATE_TEAM: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case TeamActionTypes.DELETE_TEAM: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case TeamActionTypes.GET_TEAM_LIST:
            return { ...state, loading: true };
        case TeamActionTypes.GET_TEAM_ID:
            return { ...state, loading: true };
        case TeamActionTypes.ADD_TEAM:
            return { ...state, loading: true };
        case TeamActionTypes.UPDATE_TEAM:
            return { ...state, loading: true };
        case TeamActionTypes.DELETE_TEAM:
            return { ...state, loading: true };
        case TeamActionTypes.BACK_RESET_STATE_TEAM:
            return {
                ...state,
                teamUpdated: null,
                error: null,
                team: null,
            };
        case TeamActionTypes.RESET_TEAM:
            return {
                ...state,
                loading: false,
                error: null,
                team: null,
                teamUpdated: null,
            };
        default:
            return { ...state };
    }
};

export default Team;
