// @flow
import { HospitalActionTypes } from './constants';

type HospitalAction = { type: string, payload: {} | string };

// common success
export const hospitalApiResponseSuccess = (actionType: string, data: any): HospitalAction => ({
    type: HospitalActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const hospitalApiResponseError = (actionType: string, error: string): HospitalAction => ({
    type: HospitalActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getHospitalList = (params): HospitalAction => ({
    type: HospitalActionTypes.GET_HOSPITAL_LIST,
    payload: { params },
});

export const getHospitalId = (hospital_id): HospitalAction => ({
    type: HospitalActionTypes.GET_HOSPITAL_ID,
    payload: { hospital_id },
});

export const addHospital = (hospital_name: String, hospital_request_quota: Number): HospitalAction => ({
    type: HospitalActionTypes.ADD_HOSPITAL,
    payload: { hospital_name, hospital_request_quota },
});

export const updateHospital = (
    hospital_id: Number,
    hospital_name: String,
    hospital_request_quota: Number
): HospitalAction => ({
    type: HospitalActionTypes.UPDATE_HOSPITAL,
    payload: { hospital_id, hospital_name, hospital_request_quota },
});

export const deleteHospital = (hospital_id: Number): HospitalAction => ({
    type: HospitalActionTypes.DELETE_HOSPITAL,
    payload: hospital_id,
});

export const backResetStateHospital = (): HospitalAction => ({
    type: HospitalActionTypes.BACK_RESET_STATE_HOSPITAL,
    payload: {},
});

export const resetHospital = (): HospitalAction => ({
    type: HospitalActionTypes.RESET_HOSPITAL,
    payload: {},
});
