// @flow
import { MenuActionTypes } from './constants'

// common success
export const menuApiResponseSuccess = (actionType, data) => ({
    type: MenuActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const menuApiResponseError = (actionType, error) => ({
    type: MenuActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getMenuList = (params) => ({
    type: MenuActionTypes.GET_MENU_LIST,
    payload: { params },
});