// @flow
import { PrivillegeActionTypes } from './constants.js';

type PrivillegeAction = { type: string, payload: {} | string };
// common success
export const privillegeApiResponseSuccess = (actionType: string, data: any): PrivillegeAction => ({
    type: PrivillegeActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const privillegeApiResponseError = (actionType: string, error: string): PrivillegeAction => ({
    type: PrivillegeActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getPrivillegeList = (params): PrivillegeAction => ({
    type: PrivillegeActionTypes.GET_PRIVILLGE_LIST,
    payload: { params },
});

export const getUserType = (): PrivillegeAction => ({
    type: PrivillegeActionTypes.GET_USER_TYPE,
    payload: {},
});

export const getPrivillegeId = (privillege_id): PrivillegeAction => ({
    type: PrivillegeActionTypes.GET_PRIVILLEGE_ID,
    payload: { privillege_id },
});

export const addPrivillege = (privillege_name: String, role: String): PrivillegeAction => ({
    type: PrivillegeActionTypes.ADD_PRIVILLEGE,
    payload: { privillege_name, role },
});

export const updatePrivillege = (params) => ({
    type: PrivillegeActionTypes.UPDATE_PRIVILLEGE,
    payload: { params },
});

export const deletePrivillege = (privillege_id: Number): PrivillegeAction => ({
    type: PrivillegeActionTypes.DELETE_PRIVILLEGE,
    payload: { privillege_id },
});

export const backResetStatePrivillege = (): PrivillegeAction => ({
    type: PrivillegeActionTypes.BACK_RESET_STATE_PRIVILLEGE,
    payload: {},
});

export const resetPrivillege = (): PrivillegeAction => ({
    type: PrivillegeActionTypes.RESET_PRIVILLEGE,
    payload: {},
});
