export const RequestFeatureActionTypes = {
    API_RESPONSE_SUCCESS: '@requestFeature/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@requestFeature/API_RESPONSE_ERROR',

    GET_REQUEST_FEATURE_LIST: '@requestFeature/GET_REQUEST_FEATURE_LIST',
    GET_REQUEST_FEATURE_ID: '@requestFeature/GET_REQUEST_FEATURE_ID',
    ADD_REQUEST_FEATURE: '@requestFeature/ADD_REQUEST_FEATURE',
    UPDATE_REQUEST_FEATURE: '@requestFeature/UPDATE_REQUEST_FEATURE',
    DELETE_REQUEST_FEATURE: '@requestFeature/DELETE_REQUEST_FEATURE',
    BACK_RESET_STATE_REQUEST_FEATURE: '@requestFeature/BACK_RESET_STATE_REQUEST_FEATURE',
    RESET_REQUEST_FEATURE: '@requestFeature/RESET_REQUEST_FEATURE'
};
