export const TeamActionTypes = {
    API_RESPONSE_SUCCESS: '@@team/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@team/API_RESPONSE_ERROR',

    GET_TEAM_LIST: '@@team/GET_TEAM_LIST',
    GET_TEAM_ID: '@@team/GET_TEAM_ID',
    ADD_TEAM: '@@team/ADD_TEAM',
    UPDATE_TEAM: '@@team/UPDATE_TEAM',
    UPDATE_STATE_TEAM: '@@team/UPDATE_STATE_TEAM',
    DELETE_TEAM: '@@team/DELETE_TEAM',
    BACK_RESET_STATE_TEAM: '@@team/BACK_RESET_STATE_TEAM',
    RESET_TEAM: '@@team/RESET_TEAM'
};
