// @flow
import { APICore } from './apiCore';

const api = new APICore();

// team
function userGetList(params) {
    const baseUrl = 'user/list';
    return api.get(`${baseUrl}`, params);
}

function userGetById(params) {
    const baseUrl = 'user/get';
    return api.get(`${baseUrl}`, params);
}

function userAdd(params) {
    const baseUrl = 'user/add';
    return api.create(`${baseUrl}`, params);
}

function userUpdate(params) {
    const baseUrl = 'user/update';
    return api.update(`${baseUrl}`, params);
}

function userDelete(params) {
    const baseUrl = 'user/delete';
    return api.delete(`${baseUrl}/${params}`);
}

function userChangePassword(params) {
    const baseUrl = 'user/change_password';
    return api.update(`${baseUrl}`, params);
}

export { userGetList, userGetById, userAdd, userUpdate, userChangePassword, userDelete };
