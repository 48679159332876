import { AnimatePresence } from 'framer-motion';
import { BrowserRouter } from 'react-router-dom';
import { AllRoutes } from './index';

const Routes = () => {
    return (
        <BrowserRouter>
            <AnimatePresence>
                <AllRoutes />
            </AnimatePresence>
        </BrowserRouter>
    );
};

export default Routes;
