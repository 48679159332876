import React, { Suspense } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal';
import FullLayout from '../layouts/Full';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Register = React.lazy(() => import('../pages/account/Register'));
const Confirm = React.lazy(() => import('../pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));

// dashboard
const ProjectDashboard = React.lazy(() => import('../pages/dashboard/Project'));

// hospital
const HospitalList = React.lazy(() => import('../pages/hospital/HospitalList'));
const HospitalAdd = React.lazy(() => import('../pages/hospital/AddHospital'));
const HospitalEdit = React.lazy(() => import('../pages/hospital/EditHospital'));

// team
const TeamList = React.lazy(() => import('../pages/team/TeamList'));
const TeamAdd = React.lazy(() => import('../pages/team/AddTeam'));
const TeamEdit = React.lazy(() => import('../pages/team/EditTeam'));
const UserTableList = React.lazy(() => import('../pages/team/UserTable'));

// user
const UserList = React.lazy(() => import('../pages/user/UserList'));
const UserAdd = React.lazy(() => import('../pages/user/AddUser'));
const UserEdit = React.lazy(() => import('../pages/user/EditUser'));
const ChangePassword = React.lazy(() => import('../pages/user/ChangePassword'));
const UserPrivillege = React.lazy(() => import('../pages/user/UserPrivillege'));

// product
const ProductList = React.lazy(() => import('../pages/product/ProductList'));
const ProductAdd = React.lazy(() => import('../pages/product/AddProduct'));
const AddVersion = React.lazy(() => import('../pages/product/AddVersion'));
const DetailVersion = React.lazy(() => import('../pages/product/DetailVersion'));
const ProductEdit = React.lazy(() => import('../pages/product/EditProduct'));

// requestFeature
const RequestFeatureList = React.lazy(() => import('../pages/requestFeature/RequestFeatureList'));
const RequestFeatureAdd = React.lazy(() => import('../pages/requestFeature/AddRequestFeature'));
const RequestFeatureEdit = React.lazy(() => import('../pages/requestFeature/EditRequestFeature'));
const DetailRequestFeature = React.lazy(() => import('../pages/requestFeature/DetailRequestFeature'));

// updateFeature
const UpdateFeatureList = React.lazy(() => import('../pages/updateFeature/UpdateFeatureList'));
const DetailUpdate = React.lazy(() => import('../pages/updateFeature/DetailUpdate'));

//Job - Recap
const JobRecap = React.lazy(() => import('../pages/jobRecap/JobRecap'));

//updateService
const UpdateServiceList = React.lazy(() => import('../pages/service/UpdateServiceList'));
const DetailService = React.lazy(() => import('../pages/service/DetailService'));

// Permintaan Pelayanan DetailRequestService
const RequestServiceList = React.lazy(() => import('../pages/service/RequestServiceList'));
const RequestServiceAdd = React.lazy(() => import('../pages/service/AddRequestService'));
const DetailRequestService = React.lazy(() => import('../pages/service/DetailRequestService'));

//Report
const Report = React.lazy(() => import('../pages/report/Report'));

// pages
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ErrorPageNotFoundAlt = React.lazy(() => import('../pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));

// - other
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const FAQ = React.lazy(() => import('../pages/other/FAQ'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
const Starter = React.lazy(() => import('../pages/other/Starter'));
const PreLoader = React.lazy(() => import('../pages/other/PreLoader'));
const Timeline = React.lazy(() => import('../pages/other/Timeline'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const location = useLocation();
    const { layout } = useSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case layoutConstants.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes(
        [
            { path: '/', element: <Root /> },
            {
                // public routes
                path: '/',
                element: <DefaultLayout />,
                children: [
                    {
                        path: 'account',
                        children: [
                            { path: 'login', element: <LoadComponent component={Login} /> },
                            { path: 'register', element: <LoadComponent component={Register} /> },
                            { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                            // { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                            { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                            { path: 'logout', element: <LoadComponent component={Logout} /> },
                        ],
                    },
                    {
                        path: 'error-404',
                        element: <LoadComponent component={ErrorPageNotFound} />,
                    },
                    {
                        path: 'error-500',
                        element: <LoadComponent component={ServerError} />,
                    },
                    {
                        path: 'maintenance',
                        element: <LoadComponent component={Maintenance} />,
                    },
                ],
            },
            {
                // auth protected routes
                path: '/',
                element: <PrivateRoute roles={'User'} component={Layout} />,
                children: [
                    {
                        path: 'dashboard',
                        children: [
                            {
                                path: 'project',
                                element: <LoadComponent component={ProjectDashboard} />,
                            },
                        ],
                    },
                    {
                        path: 'apps',
                        children: [
                            {
                                path: 'hospital',
                                element: <LoadComponent component={HospitalList} />,
                            },
                            {
                                path: 'hospital/add',
                                element: <LoadComponent component={HospitalAdd} />,
                            },
                            {
                                path: 'hospital/edit',
                                element: <LoadComponent component={HospitalEdit} />,
                            },
                            {
                                path: 'team',
                                element: <LoadComponent component={TeamList} />,
                            },
                            {
                                path: 'team/add',
                                element: <LoadComponent component={TeamAdd} />,
                            },
                            {
                                path: 'team/edit',
                                element: <LoadComponent component={TeamEdit} />,
                            },
                            {
                                path: 'team/detail',
                                element: <LoadComponent component={UserTableList} />,
                            },
                            {
                                path: 'user',
                                element: <LoadComponent component={UserList} />,
                            },
                            {
                                path: 'user/add',
                                element: <LoadComponent component={UserAdd} />,
                            },
                            {
                                path: 'user/edit',
                                element: <LoadComponent component={UserEdit} />,
                            },
                            {
                                path: 'user/privillege',
                                element: <LoadComponent component={UserPrivillege} />,
                            },
                            {
                                path: 'user/changePassword',
                                element: <LoadComponent component={ChangePassword} />,
                            },
                            {
                                path: 'product',
                                element: <LoadComponent component={ProductList} />,
                            },
                            {
                                path: 'product/add',
                                element: <LoadComponent component={ProductAdd} />,
                            },
                            {
                                path: 'product/edit/addVersion',
                                element: <LoadComponent component={AddVersion} />,
                            },
                            {
                                path: 'product/edit/detailVersion',
                                element: <LoadComponent component={DetailVersion} />,
                            },
                            {
                                path: 'product/edit',
                                element: <LoadComponent component={ProductEdit} />,
                            },
                            {
                                path: 'request-feature',
                                element: <LoadComponent component={RequestFeatureList} />,
                            },
                            {
                                path: 'request-feature/add',
                                element: <LoadComponent component={RequestFeatureAdd} />,
                            },
                            {
                                path: 'request-feature/edit',
                                element: <LoadComponent component={RequestFeatureEdit} />,
                            },
                            {
                                path: 'request-feature/detail',
                                element: <LoadComponent component={DetailRequestFeature} />,
                            },
                            {
                                path: 'update-feature',
                                element: <LoadComponent component={UpdateFeatureList} />,
                            },
                            {
                                path: 'update-feature/detailUpdate',
                                element: <LoadComponent component={DetailUpdate} />,
                            },
                            {
                                path: 'request-service',
                                element: <LoadComponent component={RequestServiceList} />,
                            },
                            {
                                path: 'request-service/add',
                                element: <LoadComponent component={RequestServiceAdd} />,
                            },
                            {
                                path: 'request-service/detail',
                                element: <LoadComponent component={DetailRequestService} />,
                            },
                            {
                                path: 'update-service',
                                element: <LoadComponent component={UpdateServiceList} />,
                            },
                            {
                                path: 'update-service/detailService',
                                element: <LoadComponent component={DetailService} />,
                            },
                            {
                                path: 'report',
                                element: <LoadComponent component={Report} />,
                            },
                            {
                                path: 'job_recap',
                                element: <LoadComponent component={JobRecap} />,
                            },
                        ],
                    },
                    {
                        path: 'pages',
                        children: [
                            {
                                path: 'starter',
                                element: <LoadComponent component={Starter} />,
                            },
                            {
                                path: 'pricing',
                                element: <LoadComponent component={Pricing} />,
                            },
                            {
                                path: 'error-404-alt',
                                element: <LoadComponent component={ErrorPageNotFoundAlt} />,
                            },
                            {
                                path: 'timeline',
                                element: <LoadComponent component={Timeline} />,
                            },
                            {
                                path: 'invoice',
                                element: <LoadComponent component={Invoice} />,
                            },
                            {
                                path: 'faq',
                                element: <LoadComponent component={FAQ} />,
                            },
                            {
                                path: 'preloader',
                                element: <LoadComponent component={PreLoader} />,
                            },
                        ],
                    },
                ],
            },
        ],
        location,
        { key: location.pathname }
    );
};

export { AllRoutes };
