import React from 'react';
// import ReactDOM from 'react-dom';

import App from './App';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';

// ReactDOM.render(
//     <Provider store={configureStore({})}>
//         <App />
//     </Provider>,
//     document.getElementById('root')
// );

import { createRoot } from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={configureStore({})}>
        <App />
        <Toaster />
    </Provider>
);
