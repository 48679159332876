// @flow
import { HospitalActionTypes } from './constants';

const INIT_STATE = {
    hospitalList: [],
    loading: true,
};

const Hospital = (state = INIT_STATE, action) => {
    switch (action.type) {
        case HospitalActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case HospitalActionTypes.GET_HOSPITAL_LIST: {
                    return {
                        ...state,
                        hospitalList: action.payload.data.data,
                        totalData: action.payload.data.total,
                        loading: false,
                    };
                }
                case HospitalActionTypes.GET_HOSPITAL_ID: {
                    return {
                        ...state,
                        hospital: action.payload.data.data,
                        loading: false,
                    };
                }
                case HospitalActionTypes.ADD_HOSPITAL: {
                    return {
                        ...state,
                        loading: false,
                        hospitalUpdated: true
                    };
                }
                case HospitalActionTypes.UPDATE_HOSPITAL: {
                    return {
                        ...state,
                        loading: false,
                        hospitalUpdated: true
                    };
                }
                case HospitalActionTypes.DELETE_HOSPITAL: {
                    return {
                        ...state,
                        loading: false,
                        hospitalUpdated: true
                    };
                }
                default:
                    return { ...state };
            }

        case HospitalActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case HospitalActionTypes.GET_HOSPITAL_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case HospitalActionTypes.GET_HOSPITAL_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case HospitalActionTypes.ADD_HOSPITAL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case HospitalActionTypes.UPDATE_HOSPITAL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case HospitalActionTypes.DELETE_HOSPITAL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case HospitalActionTypes.GET_HOSPITAL_LIST:
            return { ...state, loading: true };
        case HospitalActionTypes.GET_HOSPITAL_ID:
            return { ...state, loading: true };
        case HospitalActionTypes.ADD_HOSPITAL:
            return { ...state, loading: true };
        case HospitalActionTypes.UPDATE_HOSPITAL:
            return { ...state, loading: true };
        case HospitalActionTypes.DELETE_HOSPITAL:
            return { ...state, loading: true };
        case HospitalActionTypes.BACK_RESET_STATE_HOSPITAL:
            return {
                ...state,
                hospitalUpdated: null,
                error: null,
                hospital: null
            };
        case HospitalActionTypes.RESET_HOSPITAL:
            return {
                ...state,
                loading: false,
                error: null,
                hospital: null,
                hospitalUpdated: null
            };
        default:
            return { ...state };
    }
};

export default Hospital;
