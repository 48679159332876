// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import { privillegeMenuList as privillegeMenuListApi } from '../../helpers';

import { menuApiResponseSuccess, menuApiResponseError } from './actions';
import { MenuActionTypes } from './constants';

/**
 * Get list hospital
 * @param {*} payload -
 * @query - page, [product_name]
 */
function* menuList({ payload }) {
    try {
        const response = yield call(privillegeMenuListApi, payload.params);
        yield put(menuApiResponseSuccess(MenuActionTypes.GET_MENU_LIST, response.data));
    } catch (error) {
        yield put(menuApiResponseError(MenuActionTypes.GET_MENU_LIST, error));
    }
}

export function* watchProductList(): any {
    yield takeEvery(MenuActionTypes.GET_MENU_LIST, menuList);
}

function* menuSaga(): any {
    yield all([fork(watchProductList)]);
}

export default menuSaga;
