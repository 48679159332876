
export const PrivillegeActionTypes = {
    API_RESPONSE_SUCCESS: '@@privillege/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@privillege/API_RESPONSE_ERROR',

    GET_PRIVILLEGE_LIST: '@@privillege/GET_PRIVILLEGE_LIST',
    GET_PRIVILLEGE_ID: '@@privillege/GET_PRIVILLEGE_ID',
    ADD_PRIVILLEGE: '@@privillege/ADD_PRIVILLEGE',
    GET_USER_TYPE: '@@privillege/GET_USER_TYPE',
    UPDATE_PRIVILLEGE: '@@privillege/UPDATE_PRIVILLEGE',
    UPDATE_STATE_PRIVILLEGE: '@@privillege/UPDATE_STATE_PRIVILLEGE',
    DELETE_PRIVILLEGE: '@@privillege/DELETE_PRIVILLEGE',
    BACK_RESET_STATE_PRIVILLEGE: '@@privillege/BACK_RESET_STATE_PRIVILLEGE',
    RESET_PRIVILLEGE: '@@privillege/RESET_PRIVILLEGE'
};
