export const ProductActionTypes = {
    API_RESPONSE_SUCCESS: '@@product/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@product/API_RESPONSE_ERROR',

    GET_PRODUCT_LIST: '@@product/GET_PRODUCT_LIST',
    GET_PRODUCT_ID: '@@product/GET_PRODUCT_ID',
    ADD_PRODUCT: '@@product/ADD_PRODUCT',
    UPDATE_PRODUCT: '@@product/UPDATE_PRODUCT',
    DELETE_PRODUCT: '@@product/DELETE_PRODUCT',
    BACK_RESET_STATE_PRODUCT: '@@product/BACK_RESET_STATE_PRODUCT',
    RESET_PRODUCT: '@@product/RESET_PRODUCT'
};
