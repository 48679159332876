// @flow
import React, { useEffect } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { Link, Navigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';

// actions
import { resetAuth, loginUser } from '../../redux/actions';

// components
import { VerticalForm, FormInput } from '../../components';

import AccountLayout from './AccountLayout';

/* bottom link */
const BottomLink = () => {
    return (
        <footer className="footer footer-alt">
            <p className="text-muted">
                Tidak punya akun?{' '}
                <a
                    href="https://wa.me/6281325655035?text=Saya%20belum%20punya%20akun%20aplikasi%20evoluz%20Farmagitechs.%20Apakah%20bisa%20dibantu?"
                    className="text-muted ms-1"
                    target="_blank"
                    rel="noopener noreferrer">
                    <b>Hubungi CS</b>
                </a>
            </p>
        </footer>
    );
};

const Login = (): React$Element<React$FragmentType> => {
    const dispatch = useDispatch();

    const location = useLocation();
    const redirectUrl = location.state && location.state.from ? location.state.from.pathname : '/';

    useEffect(() => {
        document.title = 'Login - Evoluz';
        dispatch(resetAuth());
    }, [dispatch]);

    const { loading, userLoggedIn, user, error } = useSelector((state) => ({
        loading: state.Auth.loading,
        user: state.Auth.user,
        error: state.Auth.error,
        userLoggedIn: state.Auth.userLoggedIn,
    }));

    /*
     * form validation schema
     */
    const schemaResolver = yupResolver(
        yup.object().shape({
            nameOrEmail: yup.string().required('Masukkan Username atau Email'),
            password: yup.string().required('Masukkan Kata Sandi'),
        })
    );

    /*
     * handle form submission
     */
    const onSubmit = (formData) => {
        dispatch(loginUser(formData['nameOrEmail'], formData['password']));
    };

    return (
        <>
            {(userLoggedIn || user) && <Navigate to={redirectUrl} />}

            <AccountLayout bottomLinks={<BottomLink />}>
                <h4 className="mt-0">Masuk</h4>
                <p className="text-muted mb-4">Masukkan nama pengguna dan password untuk mengakses aplikasi</p>

                {error && (
                    <Alert variant="danger" className="my-2">
                        {error}
                    </Alert>
                )}

                <VerticalForm onSubmit={onSubmit} resolver={schemaResolver}>
                    <FormInput
                        label={'Pengguna'}
                        type="text"
                        name="nameOrEmail"
                        placeholder={'Masukkan Username atau Email'}
                        containerClass={'mb-3'}
                        autoComplete="off"
                    />
                    <FormInput
                        label={'Kata Sandi'}
                        type="password"
                        name="password"
                        placeholder={'Masukkan Kata Sandi'}
                        autoComplete="off"
                        containerClass={'mb-3'}>
                        {/* <a
                            href="https://wa.me/6281325655035?text=Saya%20lupa%20password%20aplikasi%20evoluz%20Farmagitechs.%20Apakah%20bisa%20dibantu?"
                            className="text-muted float-end"
                            target="_blank"
                            rel="noopener noreferrer">
                            <small>{'Lupa Password'}</small>
                        </a> */}
                    </FormInput>

                    <div className="d-grid mb-2 text-center">
                        <Button variant="primary" type="submit" disabled={loading}>
                            <i className="mdi mdi-login"></i> Masuk
                        </Button>
                    </div>

                    <a
                        href="https://wa.me/6281325655035?text=Saya%20lupa%20password%20aplikasi%20evoluz%20Farmagitechs.%20Apakah%20bisa%20dibantu?"
                        className="text-muted float-end"
                        target="_blank"
                        rel="noopener noreferrer">
                        <p>{'Lupa Password'}</p>
                    </a>

                    {/* social links */}
                </VerticalForm>
            </AccountLayout>
        </>
    );
};

export default Login;
