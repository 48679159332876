const zeroPad =(num, numZeros)=> {
    var n = Math.abs(num);
    var zeros = Math.max(0, numZeros - Math.floor(n).toString().length );
    var zeroString = Math.pow(10,zeros).toString().substr(1);
    if( num < 0 ) {
        zeroString = '-' + zeroString;
    }

    return zeroString+n;
}

const formatDate = (date, withTime)=> {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    
    switch (month) {
        case '1': month = 'Januari'; break;
        case '2': month = 'Februari'; break;
        case '3': month = 'Maret'; break;
        case '4': month = 'April'; break;
        case '5': month = 'Mei'; break;
        case '6': month = 'Juni'; break;
        case '7': month = 'Juli'; break;
        case '8': month = 'Agustus'; break;
        case '9': month = 'September'; break;
        case '10':month = 'Oktober'; break;
        case '11':month = 'November'; break;
        case '12':month = 'Desember'; break;
        default:
            break;
        }
    // if (month.length < 2) 
    //     month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    var time = '';
    if (withTime) {
        time = zeroPad(d.getHours(), 2)+':'+zeroPad(d.getMinutes(), 2);
    }

    return [day, month, year].join(' ')+' '+time;
}

export {formatDate}