// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    privillegeGetList as privillegeGetListApi,
    privillegeGetById as privillegeGetByIdApi,
    privillegeAdd as privillegeAddApi,
    privillegeUpdate as privillegeUpdateApi,
    userTypeGet as privillegeUserTypeGetApi,
    privillegeMenuList as privillegeMenuListApi,
} from '../../helpers';

import { privillegeApiResponseSuccess, privillegeApiResponseError } from './actions';
import { PrivillegeActionTypes } from './constants';
import { menuApiResponseSuccess } from '../actions';
import { MenuActionTypes } from '../menu/constants';

/**
 * Get list hospital
 * @param {*} payload -
 * @query - page, [team_name, team_role]
 */
function* privillegeList({ payload }) {
    try {
        const response = yield call(privillegeGetListApi, payload.params);
        yield put(privillegeApiResponseSuccess(PrivillegeActionTypes.GET_PRIVILLEGE_LIST, response.data));
    } catch (error) {
        yield put(privillegeApiResponseError(PrivillegeActionTypes.GET_PRIVILLEGE_LIST, error));
    }
}

function* getUserType() {
    try {
        const response = yield call(privillegeUserTypeGetApi);
        yield put(privillegeApiResponseSuccess(PrivillegeActionTypes.GET_USER_TYPE, response.data));
    } catch (error) {
        yield put(privillegeApiResponseError(PrivillegeActionTypes.GET_USER_TYPE, error));
    }
}

function* privillegeUpdate({ payload }) {
    try {
        const response = yield call(privillegeUpdateApi, payload.params);
        yield put(privillegeApiResponseSuccess(PrivillegeActionTypes.UPDATE_PRIVILLEGE, response.data));
        const getMenu = yield call(privillegeMenuListApi, { user_type: payload.params.user_type });
        yield put(menuApiResponseSuccess(MenuActionTypes.GET_MENU_LIST, getMenu.data));
    } catch (error) {
        yield put(privillegeApiResponseError(PrivillegeActionTypes.UPDATE_PRIVILLEGE, error));
    }
}

export function* watchprivillegeList(): any {
    yield takeEvery(PrivillegeActionTypes.GET_PRIVILLEGE_LIST, privillegeList);
}
export function* watchPrivillegeUpdate(): any {
    yield takeEvery(PrivillegeActionTypes.UPDATE_PRIVILLEGE, privillegeUpdate);
}

export function* watchgetUserType(): any {
    yield takeEvery(PrivillegeActionTypes.GET_USER_TYPE, getUserType);
}

function* PrivillegeSaga(): any {
    yield all([fork(watchprivillegeList), fork(watchgetUserType), fork(watchPrivillegeUpdate)]);
}

export default PrivillegeSaga;
